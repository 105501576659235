import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "scott" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "scott-elcyklar-kvalitet-och-innovation-på-två-hjul"
    }}>{`Scott Elcyklar: Kvalitet och Innovation på Två Hjul`}</h1>
    <p>{`Välkommen till vår dedikerade sida för Scott elcyklar! Här presenterar vi de senaste och mest innovativa elcyklarna från Scott, ett märke känt för sin höga kvalitetsstandard och banbrytande teknik. Utforska vår omfattande kollektion av Scott elcyklar, inklusive populära serier som Scott Sub, Strike, Axis, Solace och Patron. Oavsett om du letar efter en elcykel för stadspendlare eller en terrängmonterad äventyrsmaskin, har Scott något för dig.`}</p>
    <h2 {...{
      "id": "scott-elcykel-serier"
    }}>{`Scott Elcykel Serier`}</h2>
    <h3 {...{
      "id": "scott-sub"
    }}>{`Scott Sub`}</h3>
    <p><strong parentName="p">{`Scott Sub`}</strong>{`-serien är känd för sin mångsidighet och tillförlitlighet. Dessa hybrid elcyklar är perfekta både för dagliga pendlingar och längre turer på blandade underlag.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Sub Sport eRIDE 10`}</strong>{`: Utrustad med Bosch Performance CX motor och 750Wh batteri, erbjuder denna modell lång räckvidd och en smidig åktur.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sub Tour eRIDE 20`}</strong>{`: Den perfekta pendlarcykeln med en Bosch mittmotor och 500Wh batteri, idealisk för både korta och långa sträckor.`}</li>
    </ul>
    <h3 {...{
      "id": "scott-strike"
    }}>{`Scott Strike`}</h3>
    <p><strong parentName="p">{`Scott Strike`}</strong>{`-serien är en premium el-mountainbike serie designad för att ge optimal prestanda och komfort i alla terränger.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Strike eRIDE 930`}</strong>{`: Denna heldämpade mountainbike har en Bosch Performance CX motor och ett 625Wh batteri. Idealisk för tekniska nedfarter och klättringar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Strike eRIDE 940`}</strong>{`: Erbjuder en kombination av kraft och komfort med sin avancerade Bosch-motor och robusta fjädringssystem.`}</li>
    </ul>
    <h3 {...{
      "id": "scott-axis"
    }}>{`Scott Axis`}</h3>
    <p><strong parentName="p">{`Scott Axis`}</strong>{`-serien är en hybridcykel som kombinerar stadscyklingens bekvämlighet med terrängkörningens robusthet.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Axis eRIDE 30`}</strong>{`: Med en Bosch Performance-motor och 500Wh batteri, är denna modell både stilren och funktionell, perfekt för både staden och naturen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Axis eRIDE 20`}</strong>{`: En högpresterande hybrid elcykel utrustad med Shimano Deore-växlar och hydrauliska skivbromsar för maximal säkerhet och komfort.`}</li>
    </ul>
    <h3 {...{
      "id": "scott-solace"
    }}>{`Scott Solace`}</h3>
    <p><strong parentName="p">{`Scott Solace`}</strong>{`-serien är designad för gravel-äventyr och erbjuder en utmärkt kombination av prestanda och teknik.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Solace Gravel eRide 30`}</strong>{`: Denna elcykel är utrustad med en kraftfull TQ HPR50 motor och ett 360Wh batteri, vilket gör den idealisk för både asfalt och grusvägar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Solace Gravel eRide 20`}</strong>{`: En robust elcykel med avancerade SRAM-växlar och Schwalbe däck, perfekt för den äventyrslystne.`}</li>
    </ul>
    <h3 {...{
      "id": "scott-patron"
    }}>{`Scott Patron`}</h3>
    <p><strong parentName="p">{`Scott Patron`}</strong>{`-serien är skräddarsydd för att ge cyklister den bästa upplevelsen på tekniska stigar och bergspass.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Patron eRIDE 930`}</strong>{`: Utrustad med Bosch Performance CX motor och 625Wh batteri, erbjuder denna modell höj- och sänkbar sadelstolpe för optimal kontroll.`}</li>
      <li parentName="ul"><strong parentName="li">{`Patron eRIDE 920`}</strong>{`: Denna modell kombinerar kraft och komfort med sitt avancerade fjädringssystem och robusta komponenter.`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-vilken-scott-elcykel-ska-du-välja"
    }}>{`Köpguide: Vilken Scott Elcykel ska du välja?`}</h2>
    <p>{`Att välja rätt elcykel kan kännas överväldigande, men här är några riktlinjer för att hjälpa dig hitta den perfekta Scott elcykeln:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`För Daglig Pendling`}</strong>{`: Välj modeller från Scott Sub-serien som erbjuder en bra balans mellan komfort och prestanda.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Terräng och Äventyr`}</strong>{`: Scott Strike och Scott Patron-serien är idealiska val tack vare deras robusta fjädring och kraftfulla motorer.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Allroundanvändning`}</strong>{`: Scott Axis, med sin mångsidiga konstruktion och kraftfulla motor, passar utmärkt för både stad och terräng.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Gravel Roads`}</strong>{`: Scott Solace-serien erbjuder den perfekta blandningen av teknik och hållbarhet för grusvägar och bergsstigar.`}</li>
    </ol>
    <h2 {...{
      "id": "sammanfattning"
    }}>{`Sammanfattning`}</h2>
    <p>{`Oavsett om du är ute efter en pålitlig pendlarcykel eller en högpresterande mountainbike, erbjuder Scott ett brett utbud av elcyklar som uppfyller alla dina cykelbehov. Utforska våra Scott elcyklar idag och ta steget mot en mer dynamisk och hållbar cykelupplevelse.`}</p>
    <p>{`Kom och upptäck varför Scott är ett av de mest betrodda märkena inom elcyklar. Uppgradera din cykelresa med en Scott elcykel och upplev framtidens cykling redan idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      